import React, { useState, useEffect } from 'react';
import './styles.css';
import { initializeIcons } from '@uifabric/icons';
import * as firebase from "firebase";
import firebaseConfig from "./firebase.config";
import SignIn from "./SignIn";
import PageHeader from "./PageHeader";
import PageContent from "./PageContent";

firebase.initializeApp(firebaseConfig);
initializeIcons();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");

  const setUserParameters = (user) => {
    setUserId(user.uid);
    setUserEmail(user.email);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUserParameters(user);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    })
  }, []);

  const main =
    <div>
      <PageHeader
        setIsLoggedIn={setIsLoggedIn}
        userEmail={userEmail}
        firebase={firebase} />

      <PageContent
        userId={userId}
        firebase={firebase}/>
    </div>

  return (
    <div>
      {isLoggedIn ?
        <div>
          { main }
        </div> :
        <div className="wrapper">
          <SignIn setUserParameters={setUserParameters} firebase={firebase} />
        </div>
      }
    </div>
  );
}

export default App;

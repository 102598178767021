import React from 'react';
import { DefaultButton } from '@fluentui/react';


function SignIn(props) {
    const icon = { iconName: "Signin" };
    
    const signIn = () => {
        const provider = new props.firebase.auth.GoogleAuthProvider();
        props.firebase.auth().setPersistence(props.firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                props.firebase.auth().signInWithPopup(provider)
                    .then(result => {
                        console.log(result);
                        props.setUserParameters(result.user);
                    })
                    .catch(e => console.log(e.message));
            });
    }
    return(
        <DefaultButton
            iconProps={icon}
            onClick={signIn}>
            Sign in with Google
        </DefaultButton>
    );
}

export default SignIn;
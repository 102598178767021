import React, { useState } from 'react';
import { Stack, Checkbox, PrimaryButton, DialogType, DefaultButton, Dialog, DialogFooter } from 'office-ui-fabric-react';
import { IconButton } from '@fluentui/react';

function TodoItem(props) {
    const [openDeleteModal, setOpenModal] = useState(true);
    const deleteTodo = (todo) => {
        props.deleteTodo(todo);
        setOpenModal(true);
    }
    const onCheckboxChange = (todo) => {
        props.toggleDone(todo);
    }
    return (
        <Stack>
            <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal verticalAlign="center" padding={'0 0 0 10px'}>
                    <Checkbox checked={props.todo.done} onChange={() => onCheckboxChange(props.todo)} />
                    <Stack padding={'0 0 0 10px'}>
                        {props.todo.name}
                    </Stack>
                </Stack>
                <Stack horizontal>
                    {/* <IconButton iconProps={{ iconName:"edit" }} /> */}
                    <IconButton iconProps={{ iconName: "trash" }} onClick={() => { setOpenModal(!openDeleteModal) }} />
                </Stack>
            </Stack>
            <Dialog
                hidden={openDeleteModal}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Delete",
                    subText: "Are you sure you want to delete this item? This cannot be undone."
                }}
                modelProps={{
                    isBlocking: false
                }}>
                <DialogFooter>
                    <PrimaryButton text="Yes" onClick={() => deleteTodo(props.todo)} />
                    <DefaultButton text="No" onClick={() => setOpenModal(true)} />
                </DialogFooter>
            </Dialog>
        </Stack>
    );
}

export default TodoItem;
import React from 'react';
import { Stack, Label,} from 'office-ui-fabric-react';
import TodoItem from './TodoItem';

function TodoList(props) {
    return (
      <Stack vertical gap={20} padding={'30px 0'}>
        { props.todos.length > 0 ? props.todos.map((todo) => (
          <TodoItem todo={todo} key={todo.key} deleteTodo={props.deleteTodo} toggleDone={props.toggleDone}/>
        )) :
          <Label>{props.emptyText}</Label>}
      </Stack>
    );
  }

export default TodoList;
import React from 'react';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import TodoList from './TodoList';

function TodoListPivot(props) {
    const pivotStyles = {
        linkStyles: {
            selectors: {
                ':after': {
                    height: 10
                }
            }
        }
    }
    const activeTodos = props.todos.filter((todo) => {
        return !todo.done;
    });
    const completedTodos = props.todos.filter((todo) => {
        return todo.done;
    });
    return (
        <div>
            <Pivot styles={pivotStyles}>
                <PivotItem
                    headerText="active">
                    <TodoList todos={activeTodos} deleteTodo={props.deleteTodo} toggleDone={props.toggleDone} emptyText="Nothing to do" />
                </PivotItem>
                <PivotItem
                    headerText="all">
                    <TodoList todos={props.todos} deleteTodo={props.deleteTodo} toggleDone={props.toggleDone} emptyText="You haven't added any item" />
                </PivotItem>
                <PivotItem
                    headerText="completed">
                    <TodoList todos={completedTodos} deleteTodo={props.deleteTodo} toggleDone={props.toggleDone} emptyText="You haven't completed any task" />
                </PivotItem>
            </Pivot>
        </div>
    );
}

export default TodoListPivot;
import React, {useState, useEffect} from 'react';
import {ColumnDragEndLocation, Stack} from "office-ui-fabric-react";
import NewTodoBox from "./NewTodoBox";
import TodoListPivot from "./TodoListPivot";
import _ from 'lodash';
import {Nav} from "office-ui-fabric-react/lib/Nav";
import NewGroupForm from "./NewGroupForm";

const KEY_ADD_BUTTON = 'ADD_BUTTON';

function PageContent(props) {

    const [todos, setTodos] = useState([]);
    const [navLinkGroups, setNavLinkGroups] = useState([]);
    const [currentGroup, setCurrentGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        readFromDatabase(props.userId).then(r => setIsLoading(false));
    }, []);

    const readFromDatabase = async (ownerId) => {
        await props.firebase.database().ref('/users/' + ownerId).once('value', snapshot => {
            let dataVal = snapshot.val();
            let datas = _(dataVal)
                .keys()
                .map(dataKey => {
                    let cloned = _.clone(dataVal[dataKey]);
                    ColumnDragEndLocation.key = dataKey;
                    cloned.key = dataKey;
                    return cloned;
                }).value();
            sortByKey(datas);
            setTodos(datas);
            setNavLinkGroups(getNavGroups(datas));
        });
    }

    const sortByKey = (list) => {
        return list.sort((a, b) => (b.key > a.key) ? 1 : -1);
    }

    const updateDatabase = (todo, userId) => {
        let dbItemRef = props.firebase.database().ref('users/' + userId).child(todo.key);
        dbItemRef.update({
            "name": todo.name,
            "done": todo.done
        });
    }

    const removeFromDatabase = (todo, userId) => {
        let dbItemRef = props.firebase.database().ref('users/' + userId).child(todo.key);
        dbItemRef.remove();
    }

    const getNavGroups = (datas) => {
        const navGroups = datas.map(data => data.group).filter((value, index, self) => self.indexOf(value) === index);
        let initNavLinkGroups = [];
        initNavLinkGroups.push({links: []});
        navGroups.length > 0 ? setNavGroupsFromExisting(initNavLinkGroups, navGroups) : setDefaultNavGroup(initNavLinkGroups);
        setAddGroupButton(initNavLinkGroups);
        setCurrentGroup(initNavLinkGroups[0].links[0].key);
        return initNavLinkGroups;
    }

    const setNavGroupsFromExisting = (initNavLinkGroups, navGroups) => {
        navGroups.forEach(navGroup => {
            initNavLinkGroups[0].links.push({
                key: navGroup,
                name: navGroup
            })
        });
    }

    const setDefaultNavGroup = (initNavLinkGroups) => {
        initNavLinkGroups[0].links.push({
            key: 'Tasks',
            name: 'Tasks'
        })
    }

    const setAddGroupButton = (initNavLinkGroups) => {
        initNavLinkGroups[0].links.push({
            key: KEY_ADD_BUTTON,
            name: '+ Add Group',
            id: 'addButton'
        })
    }

    const addTodo = (todoName) => {
        if (todoName !== "") {
            const newTodo = {
                key: "",
                name: todoName,
                group: currentGroup,
                done: false,
                deleted: false
            }
            let dbCon = props.firebase.database().ref('users/' + props.userId);
            let newTodoRef = dbCon.push({
                name: todoName,
                group: currentGroup,
                done: false
            });
            newTodo.key = newTodoRef.key;

            const newTodos = [...todos, newTodo];
            sortByKey(newTodos);
            setTodos(newTodos);
        }
    }

    const deleteTodo = (todo) => {
        const remainingTodos = todos.filter((oldTodo) => {
            return oldTodo.key !== todo.key;
        });
        setTodos(remainingTodos);
        removeFromDatabase(todo, props.userId);
    }

    const toggleDone = (todo) => {
        todo.done = !todo.done;
        const newTodos = todos.map(a => ({...a}));
        const completedTodoIndex = newTodos.findIndex((oldTodo) => {
            return oldTodo.key === todo.key;
        });
        newTodos[completedTodoIndex].done = todo.done;
        setTodos(newTodos);
        updateDatabase(todo, props.userId);
    }

    const onGroupClick = (ev, item) => {
        item.key === KEY_ADD_BUTTON ? addNewGroup() : setCurrentGroup(item.key);
    }

    const addNewGroup = () => {
        setIsAddingNewGroup(true);
    }

    const addNewGroupToNav = (groupName) => {
        let groups = navLinkGroups[0].links;
        groups.splice(groups.length - 1, 0, {
            key: groupName,
            name: groupName
        })
        setCurrentGroup(groupName);
    }

    return (
        <>
            {isLoading ? '' :
                <div>
                    <div className="sidebar">
                        <Nav
                            selectedKey={currentGroup}
                            groups={navLinkGroups}
                            onLinkClick={onGroupClick}/>
                    </div>
                    <div className="content">
                        {isAddingNewGroup ?
                            <div>
                                <NewGroupForm
                                    addNewGroupToNav={addNewGroupToNav}
                                    setIsAddingNewGroup={setIsAddingNewGroup}/>
                            </div>
                            :
                            <div>
                                <Stack styles={{width: 500}} gap={20}>
                                    <NewTodoBox
                                        addTodo={addTodo}
                                        firebase={props.firebase}/>
                                    <TodoListPivot
                                        todos={todos.filter((todo) => todo.group === currentGroup)}
                                        deleteTodo={deleteTodo}
                                        toggleDone={toggleDone}/>
                                </Stack>
                            </div>}
                    </div>
                </div>}
        </>
    );
}

export default PageContent;
import React from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

function PageHeader(props) {
    const buttons = [
        {
            key: 'email',
            text: props.userEmail
        },
        {
            key: 'signout',
            text: 'Sign Out',
            ariaLabel: 'Sign Out',
            iconOnly: false,
            iconProps: { iconName: 'Signout' },
            onClick: () => signOut(),
        }
    ]

    const signOut = () => {
        props.firebase.auth().setPersistence(props.firebase.auth.Auth.Persistence.NONE)
            .then(() => {
                props.firebase.auth().signOut()
                    .then(() => {
                        props.setIsLoggedIn(false);
                    })
                    .catch(e => console.log(e.message));
            });
    }

    return (
        <CommandBar class="topbar" items={[{text: 'MY TODO'}]} farItems={buttons} />
    )
}

export default PageHeader;
import React, { useState } from 'react';
import { Stack, PrimaryButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

function NewTodoBox(props) {
    const [todoName, setTodoName] = useState("");
    const addTodo = () => {
        props.addTodo(todoName);
        setTodoName("");
    }

    return (
        <Stack horizontal>
            <Stack.Item grow>
                <TextField placeholder="Add new item" value={todoName} onChange={(e) =>
                    setTodoName(e.target.value)} />
            </Stack.Item>
            <PrimaryButton onClick={addTodo}>Add</PrimaryButton>
        </Stack>
    );
}

export default NewTodoBox;
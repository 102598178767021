import React, { useState } from 'react';
import { Stack, PrimaryButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

function NewGroupForm(props) {
    const [groupName, setGroupName] = useState("");
    const addNewGroupToNav = () => {
        props.addNewGroupToNav(groupName);
        props.setIsAddingNewGroup(false);
    }

    return (
        <Stack tokens={{
            childrenGap: 10,
            padding: 15}}>
            <Stack.Item align="start">
                <TextField
                    label="Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    required />
            </Stack.Item>
            <Stack.Item align="start">
                <PrimaryButton onClick={addNewGroupToNav}>Add</PrimaryButton>
            </Stack.Item>
        </Stack>
    );
}
export default NewGroupForm;